<template>
    <footer>
    </footer>
</template>

<script lang="js">

export default {
}
</script>

<style scoped></style>